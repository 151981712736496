import React from "react"

export default ({state, setState, increment, max}) => {
    if (state < max) {
        const loadmore = () => {
            if (state < max) {
                setState(state + increment) 
            } else {
                return null
            }
        }
        return (
            <div
                style={{
                    margin: "20px auto",
                    cursor: "pointer",
                    textAlign: "center",
                    outline: "none"    
                }}
                role="button"
                variant="outlined" 
                onClick={loadmore}
                onKeyDown={loadmore}
                tabIndex={0}
            >
                Mehr anzeigen
            </div> 
        )
    } else {
        return (null)
    }
}
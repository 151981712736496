import React from "react"

const HeaderPic = ({imagePath, vertical_focus="50%"}) =>
<div style={{
    margin: "-40px -30px 25px",
    width: "calc(100%+60px)",
    height: "min(60vw,400px)",
    backgroundImage: `url(${imagePath})`,
    backgroundSize: "cover",
    backgroundPosition: `50% ${vertical_focus}`
}}/>

export default React.memo(HeaderPic)
import React from "react"
import { Helmet } from "react-helmet"
import { DetailedAttributeList } from "../components/attribute"
import { DivedownBox } from "../components/divedowns"
import Ad from "../components/ad"

import Layout from "../components/layout"
import { graphql } from "gatsby"

import HeaderPic from "../components/headerpic"
import headerPic from "../../static/headerpics/bedeutung.png"


export default function Bedeutung({ data }) {
  const meanings = data.pagesJson.divedowns
    .filter((divedown) => divedown.attribute.category === 'Bedeutung')
    .sort((d1, d2) => d1.attribute.rank - d2.attribute.rank)

  return (
    <Layout>
      <Helmet>
        <title>Vornamen nach Bedeutung</title>
        <meta name="description"
          content="Über 16000 Vornamen mit Bedeutung." />
        <meta name="keywords"
          content="vornamen, bedeutung" />
        <link rel="canonical" href="/nach-bedeutung" />
      </Helmet>
      <div className="paper">
        <HeaderPic imagePath={headerPic} />
        <h1>Vornamen nach Bedeutung</h1>
        <p>Achten Sie bei der Wahl des Vornamens nicht nur auf den Klang,
        sondern auch auf die Bedeutung.
        Denn damit geben Sie Ihrem Kind eine wichtige Botschaft mit auf den Lebensweg.
        Vielleicht soll der Name die Freude ausdrücken,
        die Sie über die Ankunft Ihres Babys empfinden.
        Vielleicht soll er für eine bestimmte Eigenschaft stehen, die Ihnen wichtig ist.
        Oder vielleicht möchten Sie Ihrem Kind mit dem gewählten Namen Glück und Wohlergehen wünschen.
        Was auch immer der Name Ihres Kindes bedeuten soll
        - mit der swissmom-Namenssuche werden Sie bestimmt fündig.
          </p>
      </div>
      <div className="paper">
        <h2>Top 3 Bedeutungen</h2>
        <DetailedAttributeList
          attributes={meanings.slice(0, 3).map(d => d.attribute)}
          show_category={false} />
      </div>
      <Ad id="adnz_maxiboard_1" />
      <div className="paper">
        <h2>Alle Bedeutungen</h2>
        <DivedownBox
          divedowns={meanings.slice(4)}
          initial_limit={100}
        />
      </div>
      <Ad id="adnz_maxiboard_2" />
    </Layout>
  )
}

export const query = graphql`
  query {
    pagesJson(url: {eq: ""}) {
      divedowns {
        attribute {
          imagePath
          associated_page_url
          category
          id
          latest_births
          popularity
          rank
          title
          trend
          births {
            births
            years
          }
        }
        number_of_names
        subpage_url
      }
    }
  }
  `
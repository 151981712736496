import React, { useState } from "react"
import _ from 'lodash'
import { Link } from "gatsby"
//import Img from "gatsby-image"

import Loadmore from "./loadmore"
import { AttributeIcon } from "./attribute"
import { PopularityGraph, TrendGraph } from "./graphs"


export const DivedownBox = ({ divedowns, initial_limit = 5 }) => {
    const [divedownLimit, setDivedownLimit] = useState(initial_limit)

    // define sorting

    return (

        <div style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <div style={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
            }}>
                {divedowns.slice(0, divedownLimit).map((divedown) => (
                    <Link to={"/" + divedown.subpage_url} key={divedown.attribute.id}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px",
                        }}>
                            <AttributeIcon attribute={divedown.attribute} size={50} />
                            <p style={{ textAlign: "center", marginTop: "10px" }}>{divedown.attribute.title}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <Loadmore
                state={divedownLimit}
                setState={setDivedownLimit}
                increment={10}
                max={divedowns.length}
            />
        </div>
    )
}


export const DivedownTable = ({ divedowns, initial_limit = 8 }) => {
    const [divedownLimit, setDivedownLimit] = useState(initial_limit)
    return (
        <div style={{ width: "100%" }}>
            {divedowns.sort((d1, d2) => d1.attribute.latest_births > d2.attribute.latest_births ? -1 : 1)
                .slice(0, divedownLimit)
                .map(divedown => (
                    <div style={{ height: "40px", display: "flex", alignItems: "center" }} key={divedown.attribute.id}>
                        <AttributeIcon attribute={divedown.attribute} size={20} />
                        <Link style={{ marginLeft: "10px" }} to={"/" + divedown.subpage_url}>{divedown.attribute.title}</Link>
                        <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                            <PopularityGraph value={divedown.attribute.popularity} />
                            <TrendGraph births={divedown.attribute.births} />
                        </div>
                    </div>
                ))}
            <Loadmore
                state={divedownLimit}
                setState={setDivedownLimit}
                increment={8}
                max={divedowns.length}
            />
        </div>
    )
}

export default ({ divedowns }) => {
    const divedownByCategory = _.groupBy(divedowns, (divedown) => (divedown.attribute.category))
    const isSingleCategory = (Object.keys(divedownByCategory).length === 1)

    return (
        <div style={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "repeat(auto-fit, minmax(min(400px, 100%), 1fr))",
        }}>
            {Object.keys(divedownByCategory).map((category) => (
                <div className="paper" key={category}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <h2>{isSingleCategory ? '' : category}</h2>
                    {['Herkunft', 'Bedeutung', 'Zwei Anfangsbuchstaben', 'Drei Anfangsbuchstaben'].includes(category) ?
                        <DivedownTable
                            divedowns={divedownByCategory[category]}
                        />
                        : <DivedownBox
                            divedowns={divedownByCategory[category]}
                        />
                    }

                </div>
            ))}
        </div>
    )
}